<script>
import {mapGetters} from "vuex";
import RelativeDateTerm from "../../elements/relative-date-term.vue";

export default {
    name: "deadline-warning-form",

    components: {RelativeDateTerm},

    emits: ['update', 'close'],

    props: {
        interactionStepId: {
            type: Number,
            required: true
        },

        deadlineWarning: {
            type: Object,
            required: true
        },

        close: {
            type: Function
        },

        attach: {
            type: Function
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'deadlineWarning/loading',
            'states': 'deadlineWarning/states',
            'errors': 'deadlineWarning/errors',
        }),

        operations: function () {
            return [
                {
                    name: 'take_it_away',
                    translated: this.$t('projects::deadline_warnings.operations.take_it_away').ucFirst()
                }
            ]
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.deadlineWarning)),
        }
    },

    methods: {
        emitClose: function () {
            if (this.close) {
                this.close()
            }

            this.$emit('close', this.model)
        },

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = JSON.parse(JSON.stringify(this.model))

            return this.$store.dispatch(`deadlineWarning/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('update')
                this.emitClose()
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({interaction_step_id: this.interactionStepId}, JSON.parse(JSON.stringify(model)))
        },

        change: function () {
            if (this.attach) {
                this.attach(this.model)
            }

            if (this.model.superior === true && this.model.superiors === true) {
                this.model.superior = false
            }
        },
    },

    watch: {
        deadlineWarning: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.deadlineWarning)
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.date === false}"
                        class="form-label"
                        for="date"
                    >{{ $t('projects::deadline_warnings.columns.date').ucFirst() }}</label
                    >
                    <relative-date-term
                        id="date"
                        v-model="model.date"
                        :state="states.date === false"
                        :placeholder="$t('projects::deadline_warnings.placeholders.date').ucFirst()"
                        name="date"
                    />
                    <div :class="{'d-block': states.date === false}" class="invalid-feedback">{{ errors.date }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.number === false}"
                        class="form-label"
                        for="number"
                    >{{ $t('projects::deadline_warnings.columns.number').ucFirst() }}</label
                    >
                    <input
                        id="number"
                        v-model="model.number"
                        :class="{'border-danger': states.number === false}"
                        :placeholder="$t('projects::deadline_warnings.placeholders.number').ucFirst()"
                        class="form-control"
                        name="number"
                        type="number"
                        @change="change"
                    />
                    <div :class="{'d-block': states.number === false}" class="invalid-feedback">{{ errors.number }}</div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.superior === false}"
                        class="form-label"
                        for="superior-input"
                    >{{ $t('projects::deadline_warnings.columns.superior').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="superior-input"
                            v-model="model.superior"
                            :class="{'border-danger': states.superior === false}"
                            class="form-check-input"
                            name="superior"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.superior === false}" class="invalid-feedback">
                        {{ errors.superior }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.superiors === false}"
                        class="form-label"
                        for="superiors-input"
                    >{{ $t('projects::deadline_warnings.columns.superiors').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="superiors-input"
                            v-model="model.superiors"
                            :class="{'border-danger': states.superiors === false}"
                            class="form-check-input"
                            name="superiors"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.superiors === false}" class="invalid-feedback">
                        {{ errors.superiors }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.operation === false}"
                        class="form-label"
                        for="operation"
                    >{{ $t('projects::deadline_warnings.columns.operation').ucFirst() }}</label>
                    <select
                        id="operation"
                        v-model="model.operation"
                        :class="{'border-danger': states.operation === false}"
                        class="form-select"
                        name="operation"
                        @change="change"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::deadline_warnings.placeholders.operation').ucFirst() }}
                        </option>
                        <option v-for="(operation, key) in operations" :key="key" :value="operation.name">{{
                                operation.translated || operation.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.operation === false}" class="invalid-feedback">
                        {{ errors.operation }}
                    </div>
                </div>
                <div class="col-lg-6" v-if="model.operation">
                    <label
                        :class="{'text-danger': states.grace_period === false}"
                        class="form-label"
                        for="grace_period"
                    >{{ $t('projects::deadline_warnings.columns.grace_period').ucFirst() }}</label
                    >
                    <relative-date-term
                        id="grace_period"
                        v-model="model.grace_period"
                        :state="states.grace_period === false"
                        :placeholder="$t('projects::deadline_warnings.placeholders.grace_period').ucFirst()"
                        name="grace_period"
                    />
                    <div :class="{'d-block': states.grace_period === false}" class="invalid-feedback">{{ errors.grace_period }}</div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12 mt-4">
                    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
                        <b-button variant="secondary" @click="emitClose">{{ $t('base.close').ucFirst() }}</b-button>
                        <b-button class="ms-2" variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                    </div>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
