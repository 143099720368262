<script>
import {defineComponent} from 'vue'
import TranslationInput from "../elements/translation-input.vue";
import {mapGetters} from "vuex";
import MultipleSelect from "../elements/multiple-select.vue";
import InteractionStepForm from "./interaction-step-form.vue";
import Swal from "sweetalert2";
import RequiredFieldForm from "./required-field-form.vue";
import RequiredRelationForm from "./required-relation-form.vue";
import StatusStepForm from "./status-step-form.vue";
import RelativeDateTerm from "../elements/relative-date-term.vue";
import StatusInitiatorForm from "./status-initiator-form.vue";

export default defineComponent({
    name: "step-form",


    emits: ['saved'],

    props: {
        step: {
            type: Object,
            required: true
        },

        redirect: {
            type: Boolean,
            default: true
        },

        configuredAction: {
            type: Boolean,
            default: false
        }
    },

    components: {
        StatusInitiatorForm,
        RelativeDateTerm,
        StatusStepForm,
        RequiredRelationForm, RequiredFieldForm, InteractionStepForm, MultipleSelect, TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'step/loading',
            'states': 'step/states',
            'errors': 'step/errors',
            processes: 'process/all',
            priorities: 'priority/all',
            steps: 'step/all',
            statuses: 'status/all',
            statusList: 'status/list',
            statusStep: 'statusStep/item',
            status: 'status/item',
            statusInitiator: 'statusInitiator/item',
            interactionStep: 'interactionStep/item',
            requiredField: 'requiredField/item',
            requiredFields: 'requiredField/list',
            requiredRelation: 'requiredRelation/item',
            requiredRelations: 'requiredRelation/list',
        }),

        orderedFields: function () {
            if (!this.requiredFields || !this.requiredFields.length) {
                return []
            }

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return JSON.parse(JSON.stringify(this.requiredFields)).sort((a,b) => {
                return this.$t(a.model_translation_key) < this.$t(b.model_translation_key)
                    ? -1
                    : (
                        this.$t(a.model_translation_key) > this.$t(b.model_translation_key)
                            ? 1
                            : 0
                    )
            })
        },

        orderedRelations: function () {
            if (!this.requiredRelations || !this.requiredRelations.length) {
                return []
            }

            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            return JSON.parse(JSON.stringify(this.requiredRelations)).sort((a,b) => {
                return this.$t(a.model_translation_key) < this.$t(b.model_translation_key)
                    ? -1
                    : (
                        this.$t(a.model_translation_key) > this.$t(b.model_translation_key)
                            ? 1
                            : 0
                    )
            })
        },

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },

        process: function () {
            return this.processes?.find(process => process.id === this.model.process_id)
        },
    },

    data: function () {
        return {
            model: {},
            editedRelation: '',
            statusRelations: [],
            requiredFieldsModal: false,
            requiredRelationsModal: false,
            requiredRelationFieldsModal: false,
        }
    },

    methods: {

        save: function () {
            const method = !this.configuredAction ? (this.model.id ? 'update' : 'create') : (this.model.id ? 'update' : 'createConfig')

            const data = !this.configuredAction ? Object.assign({}, this.model) : Object.assign({}, { payload: this.model}, {push: true})

            return this.$store.dispatch(`step/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create' && this.redirect) {
                    this.$router.push(this.$t('routes.'+'/master-data/steps/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        removeRequiredField: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('requiredField/destroy', id)
                }
            });
        },

        removeRequiredRelation: function (id) {
            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    this.$store.dispatch('requiredRelation/destroy', id)
                }
            });
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },

        attachPivot: function () {
            // console.log(this.interactionStep);
        },

        attachStatusInitiator: function (statusInitiator) {
            const index = this.model.status_initiators?.findIndex(si => si.step_id === this.step.id && si.status_id === this.status.id)
            const current =
                index === -1
                ? {
                    status_id: this.status.id,
                    step_id: this.step.id
                }
                : this.model.status_initiators[index]

            const obj = Object.assign(
                {},
                current,
                statusInitiator
            )

            this.$store.dispatch('step/setStatusInitiators', { data: obj, index: index})
        },

        removeStatusInitiator: function () {
            const index = this.model.status_initiators?.findIndex(si => si.id === this.statusInitiator.id)
            this.model.status_initiators.splice(index, 1)

            this.$store.dispatch('statusInitiator/setItem', {})
        },

        editFields: function (model) {
            this.editedRelation = model
            this.requiredRelationFieldsModal = true
        },

        selectStatusInitiator: function (status) {
            this.$store.dispatch('status/setItem', status)

            let initiator = this.model.status_initiators?.find(statusInitiator => statusInitiator.status_id === status.id)

            this.$store.dispatch('statusInitiator/setItem', initiator || {})
        },
    },

    watch: {
        step: {
            immediate: true,
            handler: function (value) {
                this.setModel(value)
                this.$store.dispatch('requiredField/list', {step_id: this.model.id, model: this.process?.target_type, with: ['field']})
            }
        }
    },

    created() {
        this.$store.dispatch('step/all')
        this.$store.dispatch('process/all')
        this.$store.dispatch('priority/all')
        this.$store.dispatch('status/all')
        this.$store.dispatch('status/all')
        this.$store.dispatch('requiredField/list', {step_id: this.model.id, model: this.process?.target_type, with: ['field']})
        this.$store.dispatch('requiredRelation/list', {step_id: this.model.id})
    },

    unmounted() {
        this.$store.dispatch('status/clearItem')
        this.$store.dispatch('statusInitiator/clearItem')
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.process_id === false}"
                        class="form-label"
                        for="process_id"
                    >{{ $t('projects::steps.columns.process_id').ucFirst() }}</label>
                    <select
                        id="process_id"
                        v-model="model.process_id"
                        :class="{'border-danger': states.process_id === false}"
                        class="form-select"
                        name="process_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::steps.placeholders.process_id').ucFirst() }}
                        </option>
                        <option v-for="(process, key) in processes" :key="key" :value="process.id">{{
                                process.translation_key ? $t(process.translation_key).ucFirst() : process.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.process_id === false}" class="invalid-feedback">
                        {{ errors.process_id }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.priority_id === false}"
                        class="form-label"
                        for="priority_id"
                    >{{ $t('projects::steps.columns.priority_id').ucFirst() }}</label>
                    <select
                        id="priority_id"
                        v-model="model.priority_id"
                        :class="{'border-danger': states.priority_id === false}"
                        class="form-select"
                        name="priority_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::steps.placeholders.priority_id').ucFirst() }}
                        </option>
                        <option v-for="(priority, key) in priorities" :key="key" :value="priority.id">{{
                                priority.translation_key ? $t(priority.translation_key).ucFirst() : priority.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.priority_id === false}" class="invalid-feedback">
                        {{ errors.priority_id }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.back_step_id === false}"
                        class="form-label"
                        for="back_step_id"
                    >{{ $t('projects::steps.columns.back_step_id').ucFirst() }}</label>
                    <select
                        id="back_step_id"
                        v-model="model.back_step_id"
                        :class="{'border-danger': states.back_step_id === false}"
                        class="form-select"
                        name="back_step_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::steps.placeholders.back_step_id').ucFirst() }}
                        </option>
                        <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                step.translation_key ? $t(step.translation_key).ucFirst() : step.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.back_step_id === false}" class="invalid-feedback">
                        {{ errors.back_step_id }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('projects::steps.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('projects::steps.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('projects::steps.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('projects::steps.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.target === false}"
                        class="form-label"
                        for="target"
                    >{{ $t('projects::steps.columns.target').ucFirst() }}</label
                    >
                    <input
                        id="target"
                        v-model="model.target"
                        :class="{'border-danger': states.target === false}"
                        :placeholder="$t('projects::steps.placeholders.target').ucFirst()"
                        class="form-control"
                        name="target"
                        type="text"
                    />
                    <div :class="{'d-block': states.target === false}" class="invalid-feedback">{{ errors.target }}</div>
                </div>

                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.target_translation_key === false}"
                        class="form-label"
                        for="target_translation_key"
                    >{{ $t('projects::steps.columns.target_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.target_translation_key"
                        :error="errors.target_translation_key"
                        :placeholder="$t('projects::steps.placeholders.target_translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.target_translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.target_translation_key === false}" class="invalid-feedback">
                        {{ errors.target_translation_key }}
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <div class="col-lg-6">
                    <label
                        :class="{'text-danger': states.term === false}"
                        class="form-label"
                        for="term"
                    >{{ $t('projects::steps.columns.term').ucFirst() }}</label
                    >
                    <relative-date-term
                        id="term"
                        v-model="model.term"
                        :state="states.term === false"
                        :placeholder="$t('projects::steps.placeholders.term').ucFirst()"
                        name="term"
                    />
                    <div :class="{'d-block': states.term === false}" class="invalid-feedback">{{ errors.term }}</div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.statuses === false}"
                    class="form-label"
                    for="statuses"
                >{{ $t('projects::steps.relationships.statuses').ucFirst() }}</label
                >
                <multiple-select id="statuses" v-model="model.statuses" store="status" pivot-store="statusStep">
                    <template #pivot-form="{ close, attach }">
                        <status-step-form v-model="statusStep" :close="close" :attach="attach" :key="statusStep.id"></status-step-form>
                    </template>
                </multiple-select>
            </div>
            <div class="row mb-4">
                <label
                    :class="{'text-danger': states.interactions === false}"
                    class="form-label"
                    for="interactions"
                >{{ $t('projects::steps.relationships.interactions').ucFirst() }}</label
                >
                <multiple-select id="interactions" v-model="model.interactions" store="interaction" pivot-store="interactionStep">
                    <template #pivot-form="{ close, attach }">
                        <div class="border-top bg-soft-warning px-2">
                            <h3></h3>
                            <interaction-step-form v-model="interactionStep" :close="close" :attach="attach" :key="interactionStep.id"></interaction-step-form>
                        </div>
                    </template>
                </multiple-select>
            </div>
            <div class="row mb-4">
                <div class="col-12">
                    <label
                        :class="{'text-danger': states.statuses === false}"
                        class="form-label"
                        for="statuses"
                    >{{ $tc('projects::status_initiators.status_initiator', 1).ucFirst() }}</label
                    >
                </div>
                <div class="col-lg-3">
                    <div class="card">
                        <div class="card-body">
                            <ul class="list-unstyled bg-white px-2">
                                <li class="border-bottom cursor-pointer list-item px-2 rounded" :class="{'bg-primary text-white': iteratedStatus.id === status.id}" v-for="(iteratedStatus, index) in model.statuses" :key="index" @click="selectStatusInitiator(iteratedStatus)">
                                    {{ iteratedStatus.translation_key ? $t(iteratedStatus.translation_key).ucFirst().truncate(30) : iteratedStatus.name }}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title">
                                <span v-if="!status.id" class="text-muted">{{ $t('base.please_select').ucFirst() }}</span>
                                <strong>
                                    {{ status.translation_key ? $t(status.translation_key).ucFirst() : status.name }}
                                </strong>
                                <b-button v-if="statusInitiator.id" class="ms-2 text-danger" variant="white" size="sm" @click="removeStatusInitiator">
                                    <i class="fas fa-trash-alt"></i>
                                </b-button>
                            </div>
                        </div>
                        <div class="card-body">
                            <status-initiator-form v-if="status.id" v-model="statusInitiator" :fix="true" :close="close" @updated="attachStatusInitiator" :key="statusInitiator.id"></status-initiator-form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    class="form-label"
                >{{ $tc('projects::required_fields.required_field', 2).ucFirst() }}</label
                >
                <div class="col-12 mb-3">
                    <span class="badge badge-soft-primary user-select-none px-2 py-1 me-2" v-for="(requiredField, index) in orderedFields" :key="index">
                        <span>{{ $t(requiredField.model_translation_key).ucFirst() }} | {{ $t(requiredField.column_translation_key || (requiredField.field?.translation_key || requiredField.field?.name) || requiredField.column) }}</span>
                        <span class="ms-2 text-danger cursor-pointer" @click="removeRequiredField(requiredField.id)">
                            <i class="fas fa-trash"></i>
                        </span>
                    </span>
                </div>
                <div class="col-12">
                    <b-button variant="outline-primary" size="sm" @click="requiredFieldsModal = !requiredFieldsModal">{{ $t('base.add_new_item', {item: $tc('projects::required_fields.required_field', 2)}).ucFirst() }}</b-button>
                </div>
            </div>
            <div class="row mb-4">
                <label
                    class="form-label"
                >{{ $tc('projects::required_relations.required_relation', 2).ucFirst() }}</label
                >
                <div class="col-12 mb-3">
                    <span class="badge badge-soft-primary user-select-none px-2 py-1 me-2" v-for="(relation, index) in orderedRelations" :key="index">
                        <span>{{ $t(relation.model_translation_key).ucFirst() }} | {{ $t(relation.relation_translation_key) }}</span>
                        <span class="ms-2 text-dark cursor-pointer" @click="editFields(relation.model)">
                            <i class="fas fa-edit"></i>
                        </span>
                        <span class="ms-2 text-danger cursor-pointer" @click="removeRequiredRelation(relation.id)">
                            <i class="fas fa-trash"></i>
                        </span>
                    </span>
                </div>
                <div class="col-12">
                    <b-button variant="outline-primary" size="sm" @click="requiredRelationsModal = !requiredRelationsModal">{{ $t('base.add_new_item', {item: $tc('projects::required_relations.required_relation', 1)}).ucFirst() }}</b-button>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
            <b-modal v-model="requiredFieldsModal" :title="(step.translation_key ? $t(step.translation_key).ucFirst() : step.name) + ': ' + $t('base.add_new_item', {item: $tc('projects::required_fields.required_field', 1)})" :hide-footer="true" size="xl">
                <required-field-form v-if="requiredFieldsModal" :step="model" :required-field="requiredField" :redirect="false" @saved="() => requiredFieldsModal = !requiredFieldsModal"></required-field-form>
            </b-modal>
            <b-modal v-model="requiredRelationsModal" :title="(step.translation_key ? $t(step.translation_key).ucFirst() : step.name) + ': ' + $t('base.add_new_item', {item: $tc('projects::required_relations.required_relation', 1)})" :hide-footer="true" size="xl">
                <required-relation-form v-if="requiredRelationsModal" :step="model" :required-relation="requiredRelation" :redirect="false" @saved="() => requiredRelationsModal = !requiredRelationsModal"></required-relation-form>
            </b-modal>
            <b-modal v-model="requiredRelationFieldsModal" :title="(step.translation_key ? $t(step.translation_key).ucFirst() : step.name) + ': ' + $t('base.add_new_item', {item: $tc('projects::required_relations.required_relation', 1)})" :hide-footer="true" size="xl">
                <required-field-form v-if="requiredRelationFieldsModal" :step="model" :fix-model="editedRelation" :step-id="model.id" :required-field="requiredField" :redirect="false" @saved="() => requiredFieldsModal = !requiredFieldsModal"></required-field-form>
            </b-modal>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
