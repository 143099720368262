<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div v-if="!step && !stepId" class="col-lg-4">
                    <label
                        :class="{'text-danger': states.step_id === false}"
                        class="form-label"
                        for="step_id"
                    >{{ $t('projects::required_fields.columns.step_id').ucFirst() }}</label>
                    <select
                        id="step_id"
                        v-model="model.step_id"
                        :class="{'border-danger': states.step_id === false}"
                        class="form-select"
                        name="step_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_fields.placeholders.step_id').ucFirst() }}
                        </option>
                        <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                step.translation_key ? $t(step.translation_key).ucFirst() : step.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.step_id === false}" class="invalid-feedback">
                        {{ errors.step_id }}
                    </div>
                </div>
                <div class="col-lg-4" v-if="!fixModel">
                    <label
                        :class="{'text-danger': states.model === false}"
                        class="form-label"
                        for="model"
                    >{{ $t('projects::required_fields.columns.model').ucFirst() }}</label
                    >
                    <select
                        id="model"
                        v-model="model.model"
                        :class="{'border-danger': states.model === false}"
                        class="form-select"
                        name="model"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_fields.placeholders.model').ucFirst() }}
                        </option>
                        <option v-for="(model, key) in models" :key="key" :value="model.name">{{
                                model.translation_key ? $t(model.translation_key).ucFirst() : model.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.model === false}" class="invalid-feedback">{{ errors.model }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.column === false}"
                        class="form-label"
                        for="column"
                    >{{ $t('projects::required_fields.columns.column').ucFirst() }}</label
                    >
                    <select
                        id="column"
                        v-model="model.column"
                        :class="{'border-danger': states.column === false}"
                        class="form-select"
                        name="column"
                        :disabled="model.field_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_fields.placeholders.column').ucFirst() }}
                        </option>
                        <option v-for="(column, key) in columns" :key="key" :value="column.name">{{
                                column.translation_key ? $t(column.translation_key).ucFirst() : column.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.column === false}" class="invalid-feedback">{{ errors.column }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.field_id === false}"
                        class="form-label"
                        for="field_id"
                    >{{ $t('projects::required_fields.columns.field_id').ucFirst() }}</label
                    >
                    <select
                        id="field_id"
                        v-model="model.field_id"
                        :class="{'border-danger': states.field_id === false}"
                        name="field_id"
                        class="form-select"
                        :disabled="model.column"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_fields.placeholders.field_id').ucFirst() }}
                        </option>
                        <option v-for="(field, key) in fields" :key="key" :value="field.id">{{
                                field.translation_key ? $t(field.translation_key).ucFirst() : field.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.field_id === false}" class="invalid-feedback">{{ errors.field_id }}</div>
                </div>


            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "required-field-form",

    emits: ['saved'],

    props: {
        requiredField: {
            type: Object,
            required: true
        },
        step: {
            type: Object
        },
        redirect: {
            type: Boolean,
            default: true
        },
        fixModel: {
            type: String
        },
        stepId: {
            type: Number
        }
    },

    computed: {
        ...mapGetters({
            loading: 'requiredField/loading',
            states: 'requiredField/states',
            errors: 'requiredField/errors',
            requiredFields: 'requiredField/all',
            fieldLoading: 'field/loading',
            fields: 'field/list',
            models: 'model/list',
            columns: 'model/item',
            steps: 'step/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {},
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`requiredField/${method}`, this.model).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                this.$store.dispatch('requiredField/clearItem')
                if (method === 'create' && this.redirect) {
                    this.$router.push(this.$t('routes.' + '/requiredField/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            const base = {with: ['field']}

            if (this.step) {
                base.step_id = this.step.id
            }

            if (this.stepId) {
                base.step_id = this.stepId
            }

            if (this.fixModel) {
                base.model = this.fixModel
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },

        fetchRequiredFields: function () {
            this.$store.dispatch('requiredField/all', {step_id: this.stepId || this.model.step_id})
        },

        fetchFields: function () {
            this.$store.dispatch('field/list', {owner_type: this.fixModel || this.model.model})
        },

        fetchModels: function () {
            this.$store.dispatch('model/list')
        },

        fetchColumns: function () {
            this.$store.dispatch('model/show', {id: this.fixModel || this.model.model})
        },
    },

    watch: {
        requiredField: {
            immediate: true,
            handler: function(value) {
                this.setModel(value)
            },
        },

        'model.model': {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (value) {
                    this.fetchFields();
                    this.fetchColumns();
                }
            },
        },

        fixModel: {
            immediate: true,
            handler: function () {
                this.fetchModels();
            },
        },

        stepId: {
            immediate: true,
            handler: function () {
                this.fetchRequiredFields()
            },
        },

        'step.id': {
            deep: true,
            immediate: true,
            handler: function () {
                this.fetchRequiredFields()
            },
        }
    },

    mounted() {
        this.$store.dispatch('field/clearList')
    }
}
</script>

<style scoped>

</style>
