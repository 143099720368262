<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "interaction-step-form",

    emits: ['update', 'close'],

    props: {
        modelValue: {
            type: Object,
            required: true
        },

        close: {
            type: Function
        },

        attach: {
            type: Function
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'step/loading',
            'states': 'step/states',
            'errors': 'step/errors',
            priorities: 'priority/all',
        }),
    },

    data: function () {
        return {
            model: this.modelValue
        }
    },

    methods: {
        emitClose: function () {
            if (this.close) {
                this.close()
            }

            this.$emit('close', this.model)
        },

        change: function () {
            if (this.attach) {
                this.attach(this.model)
            }
        },
    },

    watch: {
        modelValue: function (mv) {
            this.model = mv
        }
    },

    created() {
        this.$store.dispatch('priority/all')
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row">
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.deadline === false}"
                        class="form-label"
                        for="deadline"
                    >{{ $t('projects::interaction_step.columns.deadline').ucFirst() }}</label
                    >
                    <input
                        id="deadline"
                        v-model="model.deadline"
                        :class="{'border-danger': states.deadline === false}"
                        :placeholder="$t('projects::interaction_step.placeholders.deadline').ucFirst()"
                        class="form-control"
                        name="deadline"
                        type="datetime-local"
                        @change="change"
                    />
                    <div :class="{'d-block': states.deadline === false}" class="invalid-feedback">{{ errors.deadline }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.priority_id === false}"
                        class="form-label"
                        for="priority_id"
                    >{{ $t('projects::interaction_step.columns.priority_id').ucFirst() }}</label>
                    <select
                        id="priority_id"
                        v-model="model.priority_id"
                        :class="{'border-danger': states.priority_id === false}"
                        class="form-select"
                        name="priority_id"
                        @change="change"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::interaction_step.placeholders.priority_id').ucFirst() }}
                        </option>
                        <option v-for="(priority, key) in priorities" :key="key" :value="priority.id">{{
                                priority.translation_key ? $t(priority.translation_key).ucFirst() : priority.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.priority_id === false}" class="invalid-feedback">
                        {{ errors.priority_id }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.criterion_requirement === false}"
                        class="form-label"
                        for="criterion_requirement-input"
                    >{{ $t('projects::interaction_step.columns.criterion_requirement').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="criterion_requirement-input"
                            v-model="model.criterion_requirement"
                            :class="{'border-danger': states.criterion_requirement === false}"
                            class="form-check-input"
                            name="criterion_requirement"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.criterion_requirement === false}" class="invalid-feedback">
                        {{ errors.criterion_requirement }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.transferable === false}"
                        class="form-label"
                        for="transferable-input"
                    >{{ $t('projects::interaction_step.columns.transferable').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="transferable-input"
                            v-model="model.transferable"
                            :class="{'border-danger': states.transferable === false}"
                            class="form-check-input"
                            name="transferable"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.transferable === false}" class="invalid-feedback">
                        {{ errors.transferable }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.only_successful_transferable === false}"
                        class="form-label"
                        for="only_successful_transferable-input"
                    >{{ $t('projects::interaction_step.columns.only_successful_transferable').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="only_successful_transferable-input"
                            v-model="model.only_successful_transferable"
                            :class="{'border-danger': states.only_successful_transferable === false}"
                            class="form-check-input"
                            name="only_successful_transferable"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.only_successful_transferable === false}" class="invalid-feedback">
                        {{ errors.only_successful_transferable }}
                    </div>
                </div>
                <div class="col-lg-3 mb-4">
                    <label
                        :class="{'text-danger': states.expected_volume === false}"
                        class="form-label"
                        for="expected_volume"
                    >{{ $t('projects::interaction_step.columns.expected_volume').ucFirst() }}</label
                    >
                    <input
                        id="expected_volume"
                        v-model="model.expected_volume"
                        :class="{'border-danger': states.expected_volume === false}"
                        :placeholder="$t('projects::interaction_step.placeholders.expected_volume').ucFirst()"
                        class="form-control"
                        name="expected_volume"
                        type="number"
                        @change="change"
                    />
                    <div :class="{'d-block': states.expected_volume === false}" class="invalid-feedback">{{ errors.expected_volume }}</div>
                </div>
            </div>
            <div class="row">
            </div>
            <div class="row">
                <div class="col-lg-12 mb-4">
                    <div class="h-100 w-100 d-flex align-items-center justify-content-center">
                        <b-button variant="primary" @click="emitClose">{{ $t('base.close').ucFirst() }}</b-button>
                    </div>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
