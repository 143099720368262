<script>
import {mapGetters} from "vuex";

export default {
    name: "status-initiator-rule-form",

    emits: ['saved', 'updated'],

    props: {
        statusInitiatorRule: {
            type: Object,
            required: true
        },

        statusInitiatorId: {
            type: Number
        },

        fix: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'statusInitiatorRule/loading',
            states: 'statusInitiatorRule/states',
            errors: 'statusInitiatorRule/errors',
            interactions: 'interaction/all',
        }),

        comparisons: function () {
            return [
                {
                    value: '=',
                    label: this.$t('projects::status_initiator_rules.comparisons.equals')
                },
                {
                    value: '!=',
                    label: this.$t('projects::status_initiator_rules.comparisons.not_equals')
                },
                {
                    value: '<',
                    label: this.$t('projects::status_initiator_rules.comparisons.lesser')
                },
                {
                    value: '>',
                    label: this.$t('projects::status_initiator_rules.comparisons.greater')
                }
            ]
        },

        filteredInteractions: function () {
            return this.interactions?.filter(interaction => !!interaction.event_type)
        },
    },

    data: function () {
        return {
            model: {},
            columns: []
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`statusInitiatorRule/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                /*if (method === 'create') {
                    const translationKey = this.own ? 'routes./projects/my-projects/:id(\\d+)' : 'routes./projects/:id(\\d+)'
                    this.$router.push(this.$t(translationKey, {id: this.model.id}).rmValidation())
                }*/
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
        },

        updated: function () {
            this.$emit('updated', this.model)
        },
    },

    watch: {
        statusInitiatorRule: {
            immediate: true,
            handler: function (value) {
                this.setModel(value)
            },
        },

        'model.model': {
            immediate: true,
            deep: true,
            handler: function (value) {
                this.$store.dispatch('model/show', {id: value}).then(response => {
                    this.columns = response.data
                })

            }
        }
    },

    created() {
        this.$store.dispatch('interaction/all')
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.model === false}"
                        class="form-label"
                        for="model"
                    >{{ $t('projects::status_initiator_rules.columns.model').ucFirst() }}</label>
                    <select
                        id="model"
                        v-model="model.model"
                        :class="{'border-danger': states.model === false}"
                        class="form-select"
                        name="model"
                        @change="updated"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::status_initiator_rules.placeholders.model').ucFirst() }}
                        </option>
                        <option v-for="(interaction, key) in filteredInteractions" :key="key" :value="interaction.event_type">{{
                                interaction.translation_key ? $t(interaction.translation_key).ucFirst() : interaction.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.model === false}" class="invalid-feedback">
                        {{ errors.model }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.column === false}"
                        class="form-label"
                        for="column"
                    >{{ $t('projects::status_initiator_rules.columns.column').ucFirst() }}</label>
                    <select
                        id="column"
                        v-model="model.column"
                        :class="{'border-danger': states.column === false}"
                        class="form-select"
                        name="column"
                        @change="updated"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::status_initiator_rules.placeholders.column').ucFirst() }}
                        </option>
                        <option v-for="(column, key) in columns" :key="key" :value="column.name">{{
                                column.translation_key ? $t(column.translation_key).ucFirst() : column.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.column === false}" class="invalid-feedback">
                        {{ errors.column }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.comparison === false}"
                        class="form-label"
                        for="comparison"
                    >{{ $t('projects::status_initiator_rules.columns.comparison').ucFirst() }}</label>
                    <select
                        id="comparison"
                        v-model="model.comparison"
                        :class="{'border-danger': states.comparison === false}"
                        class="form-select"
                        name="comparison"
                        @change="updated"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::status_initiator_rules.placeholders.comparison').ucFirst() }}
                        </option>
                        <option v-for="(comparison, key) in comparisons" :key="key" :value="comparison.value">{{
                                comparison.label
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.comparison === false}" class="invalid-feedback">
                        {{ errors.comparison }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.value === false}"
                        class="form-label"
                        for="value"
                    >{{ $t('projects::status_initiator_rules.columns.value').ucFirst() }}</label
                    >
                    <input
                        id="value"
                        v-model="model.value"
                        :class="{'border-danger': states.value === false}"
                        :placeholder="$t('projects::status_initiator_rules.placeholders.value').ucFirst()"
                        class="form-control"
                        name="value"
                        type="text"
                        @change="updated"
                    />
                    <div :class="{'d-block': states.value === false}" class="invalid-feedback">{{ errors.value }}</div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!fix" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
