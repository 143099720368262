<script>
import {defineComponent} from 'vue'
import {mapGetters} from "vuex";

export default defineComponent({
    name: "status-step-form",

    emits: ['update', 'close'],

    props: {
        modelValue: {
            type: Object,
            required: true
        },

        close: {
            type: Function
        },

        attach: {
            type: Function
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'step/loading',
            'states': 'step/states',
            'errors': 'step/errors'
        }),
    },

    data: function () {
        return {
            model: this.modelValue
        }
    },

    methods: {
        emitClose: function () {
            if (this.close) {
                this.close()
            }

            this.$emit('close', this.model)
        },

        change: function () {
            if (this.attach) {
                this.attach(this.model)
            }
        },
    },

    watch: {
        modelValue: function (mv) {
            this.model = mv
        }
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row px-2">
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.is_default === false}"
                        class="form-label"
                        for="is_default-input"
                    >{{ $t('projects::status_steps.columns.is_default').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="is_default-input"
                            v-model="model.is_default"
                            :class="{'border-danger': states.is_default === false}"
                            class="form-check-input"
                            name="is_default"
                            type="checkbox"
                        />
                    </div>
                    <div :class="{'d-block': states.is_default === false}" class="invalid-feedback">
                        {{ errors.is_default }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.step_initiator === false}"
                        class="form-label"
                        for="step_initiator-input"
                    >{{ $t('projects::status_steps.columns.step_initiator').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="step_initiator-input"
                            v-model="model.step_initiator"
                            :class="{'border-danger': states.step_initiator === false}"
                            class="form-check-input"
                            name="step_initiator"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.step_initiator === false}" class="invalid-feedback">
                        {{ errors.step_initiator }}
                    </div>
                </div>
                <div class="col-lg-2 mb-4">
                    <label
                        :class="{'text-danger': states.back_initiator === false}"
                        class="form-label"
                        for="back_initiator-input"
                    >{{ $t('projects::status_steps.columns.back_initiator').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md">
                        <input
                            id="back_initiator-input"
                            v-model="model.back_initiator"
                            :class="{'border-danger': states.back_initiator === false}"
                            class="form-check-input"
                            name="back_initiator"
                            type="checkbox"
                            @change="change"
                        />
                    </div>
                    <div :class="{'d-block': states.back_initiator === false}" class="invalid-feedback">
                        {{ errors.back_initiator }}
                    </div>
                </div>
                <div class="col-lg-1 mb-4">
                    <div class="h-100 w-100 d-flex align-items-end justify-content-end">
                        <b-button variant="primary" @click="emitClose">{{ $t('base.close').ucFirst() }}</b-button>
                    </div>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
