<script>
import {defineComponent} from 'vue'
import TranslationInput from "../elements/translation-input.vue";
import {mapGetters} from "vuex";

export default defineComponent({
    name: "process-form",

    emits: ['saved'],

    props: {
        process: {
            type: Object,
            required: true
        }
    },

    components: {TranslationInput},

    computed: {
        ...mapGetters({
            'loading': 'process/loading',
            'states': 'process/states',
            'errors': 'process/errors'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {},
            targetTypes: [
                {
                    label: this.$tc('partners::partners.partner', 1).ucFirst(),
                    value: 'Appon\\Partners\\Models\\Partner'
                }
            ],
        }
    },

    methods: {

        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`process/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.'+'/master-data/processes/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            this.model = Object.assign({}, JSON.parse(JSON.stringify(model)))
            if (!this.model.target_type && this.targetTypes && this.targetTypes.length === 1) {
                this.model.target_type = this.targetTypes[0].value
            }
        },
    },

    watch: {
        process: function (value) {
            this.setModel(value)
        }
    },

    created() {
        this.setModel(this.process)
    }
})
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.name === false}"
                        class="form-label"
                        for="name"
                    >{{ $t('projects::processes.columns.name').ucFirst() }}</label
                    >
                    <input
                        id="name"
                        v-model="model.name"
                        :class="{'border-danger': states.name === false}"
                        :placeholder="$t('projects::processes.placeholders.name').ucFirst()"
                        class="form-control"
                        name="name"
                        type="text"
                    />
                    <div :class="{'d-block': states.name === false}" class="invalid-feedback">{{ errors.name }}</div>
                </div>

                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="form-label"
                        for="translation_key"
                    >{{ $t('projects::processes.columns.translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.translation_key"
                        :error="errors.translation_key"
                        :placeholder="$t('projects::processes.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                        :state="states.translation_key"
                    ></translation-input>
                    <div :class="{'d-block': states.translation_key === false}" class="invalid-feedback">
                        {{ errors.translation_key }}
                    </div>
                </div>

                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.target_type === false}"
                        class="form-label"
                        for="target_type"
                    >{{ $t('projects::processes.columns.target_type').ucFirst() }}</label
                    >
                    <select
                        id="type"
                        v-model="model.target_type"
                        :class="{'border-danger': states.target_type === false}"
                        class="form-select"
                        name="type"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::processes.placeholders.target_type').ucFirst() }}
                        </option>
                        <option v-for="(item, index) in targetTypes" :key="index" :value="item.value">{{ item.label }}</option>
                    </select>
                    <div :class="{'d-block': states.target_type === false}" class="invalid-feedback">
                        {{ errors.target_type }}
                    </div>
                </div>
            </div>

            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
