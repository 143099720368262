<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div v-if="!step" class="col-lg-4">
                    <label
                        :class="{'text-danger': states.step_id === false}"
                        class="form-label"
                        for="step_id"
                    >{{ $t('projects::required_relations.columns.step_id').ucFirst() }}</label>
                    <select
                        id="step_id"
                        v-model="model.step_id"
                        :class="{'border-danger': states.step_id === false}"
                        class="form-select"
                        name="step_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_relations.placeholders.step_id').ucFirst() }}
                        </option>
                        <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                step.translation_key ? $t(step.translation_key).ucFirst() : step.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.step_id === false}" class="invalid-feedback">
                        {{ errors.step_id }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.model === false}"
                        class="form-label"
                        for="model"
                    >{{ $t('projects::required_relations.columns.model').ucFirst() }}</label
                    >
                    <select
                        id="model"
                        v-model="model.model"
                        :class="{'border-danger': states.model === false}"
                        class="form-select"
                        name="model"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_relations.placeholders.model').ucFirst() }}
                        </option>
                        <option v-for="(model, key) in models" :key="key" :value="model.name">{{
                                model.translation_key ? $t(model.translation_key).ucFirst() : model.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.model === false}" class="invalid-feedback">{{ errors.model }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.relation === false}"
                        class="form-label"
                        for="relation"
                    >{{ $t('projects::required_relations.columns.relation').ucFirst() }}</label
                    >
                    <select
                        id="relation"
                        v-model="model.relation"
                        :class="{'border-danger': states.relation === false}"
                        class="form-select"
                        name="relation"
                        :disabled="model.field_id"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::required_relations.placeholders.relation').ucFirst() }}
                        </option>
                        <option v-for="(relation, key) in relations" :key="key" :value="relation.name">{{
                                relation.translation_key ? $t(relation.translation_key).ucFirst() : relation.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.relation === false}" class="invalid-feedback">{{ errors.relation }}</div>
                </div>


            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "required-relation-form",

    emits: ['saved'],

    props: {
        requiredRelation: {
            type: Object,
            required: true
        },
        step: {
            type: Object
        },
        redirect: {
            type: Boolean,
            default: true
        }
    },

    computed: {
        ...mapGetters({
            loading: 'requiredRelation/loading',
            states: 'requiredRelation/states',
            errors: 'requiredRelation/errors',
            steps: 'step/all',
            models: 'model/list',
            relations: 'model/relations'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: {},
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`requiredRelation/${method}`, this.model).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                this.$store.dispatch('requiredRelation/clearItem')
                if (method === 'create' && this.redirect) {
                    this.$router.push(this.$t('routes.' + '/requiredRelation/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },

        setModel: function (model = {}) {
            const base = {}

            if (this.step) {
                base.step_id = this.step.id
            }

            this.model = Object.assign(base, JSON.parse(JSON.stringify(model)));
        },

        fetchModels: function () {
            this.$store.dispatch('model/list')
        },

        fetchRelations: function () {
            this.$store.dispatch('model/relations', {model: this.model.model})
        },
    },

    watch: {
        requiredField: function (value) {
            this.setModel(value)
        },

        'model.model': {
            immediate: true,
            deep: true,
            handler: function (value) {
                if (value) {
                    this.fetchRelations();
                }
            },
        },
    },

    created() {
        this.setModel(this.requiredField)
        this.fetchModels()
    }
}
</script>

<style scoped>

</style>
