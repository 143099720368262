<script>
import {mapGetters} from "vuex";
import StatusInitiatorRuleForm from "./status-initiator-rule-form.vue";

export default {
    name: "status-initiator-form",
    components: {StatusInitiatorRuleForm},

    emits: ['saved', 'updated', 'update:modelValue'],

    props: {
        modelValue: {
            type: Object,
            required: true
        },

        fix: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapGetters({
            loading: 'statusInitiator/loading',
            states: 'statusInitiator/states',
            errors: 'statusInitiator/errors',
        })
    },

    data: function () {
        return {
            model: {}
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            const data = Object.assign({}, this.model)

            return this.$store.dispatch(`statusInitiator/${method}`, data).then(saved => {
                this.setModel(saved)
                this.$emit('saved')
                /*if (method === 'create') {
                    const translationKey = this.own ? 'routes./projects/my-projects/:id(\\d+)' : 'routes./projects/:id(\\d+)'
                    this.$router.push(this.$t(translationKey, {id: this.model.id}).rmValidation())
                }*/
            })
        },

        setModel: function (model = {}) {
            this.model = JSON.parse(JSON.stringify(model))
        },

        addRule: function () {
            if (! Array.isArray(this.model.status_initiator_rules)) {
                this.model.status_initiator_rules = [];
            }

            this.model.status_initiator_rules.push({
                status_initiator_id: this.model.id
            });

            this.updated()
        },

        updateRule: function (index, rule) {
            this.model.status_initiator_rules[index] = Object.assign({}, this.model.status_initiator_rules[index], rule)
            this.updated()
        },

        removeRule: function (index) {
            this.model.status_initiator_rules?.splice(index, 1)
            this.updated()
        },

        updated: function () {
            this.$emit('updated', JSON.parse(JSON.stringify(this.model)))
        },
    },

    watch: {
        modelValue: {
            immediate: true,
            handler: function (value) {
                this.setModel(value)
            },
        }
    }
}
</script>

<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-3" v-if="!fix">
                    <label
                        :class="{'text-danger': states.step_id === false}"
                        class="form-label"
                        for="step_id"
                    >{{ $t('projects::status_initiators.columns.step_id').ucFirst() }}</label>
                    <select
                        id="step_id"
                        v-model="model.step_id"
                        :class="{'border-danger': states.step_id === false}"
                        class="form-select"
                        name="step_id"
                        @change="updated"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::status_initiators.placeholders.step_id').ucFirst() }}
                        </option>
                        <option v-for="(step, key) in steps" :key="key" :value="step.id">{{
                                step.translation_key ? $t(step.translation_key).ucFirst() : step.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.step_id === false}" class="invalid-feedback">
                        {{ errors.step_id }}
                    </div>
                </div>
                <div class="col-lg-3" v-if="!fix">
                    <label
                        :class="{'text-danger': states.status_id === false}"
                        class="form-label"
                        for="status_id"
                    >{{ $t('projects::status_initiators.columns.status_id').ucFirst() }}</label>
                    <select
                        id="status_id"
                        v-model="model.status_id"
                        :class="{'border-danger': states.status_id === false}"
                        class="form-select"
                        name="status_id"
                        @change="updated"
                    >
                        <option :value="model.id ? null : undefined">
                            {{ $t('projects::status_initiators.placeholders.status_id').ucFirst() }}
                        </option>
                        <option v-for="(status, key) in statuses" :key="key" :value="status.id">{{
                                status.translation_key ? $t(status.translation_key).ucFirst() : status.name
                            }}
                        </option>
                    </select>
                    <div :class="{'d-block': states.status_id === false}" class="invalid-feedback">
                        {{ errors.status_id }}
                    </div>
                </div>
                <div class="col-lg-3">
                    <label
                        :class="{'text-danger': states.priority === false}"
                        class="form-label"
                        for="priority"
                    >{{ $t('projects::status_initiators.columns.priority').ucFirst() }}</label
                    >
                    <input
                        id="priority"
                        v-model="model.priority"
                        :class="{'border-danger': states.priority === false}"
                        :placeholder="$t('projects::status_initiators.placeholders.priority').ucFirst()"
                        class="form-control"
                        name="priority"
                        type="number"
                        @change="updated"
                    />
                    <div :class="{'d-block': states.priority === false}" class="invalid-feedback">{{ errors.priority }}</div>
                </div>

                <div class="col-lg-2">
                    <label
                        class="form-label"
                        for="existence"
                    >{{ $t('projects::status_initiators.columns.existence').ucFirst() }}</label
                    >
                    <div class="form-check form-switch form-switch-md mb-3 ps-0">
                        <input id="existence" v-model="model.existence"
                               class="form-check-input float-none mx-3" type="checkbox"
                               @change="updated"
                        >
                    </div>
                    <div :class="{'d-block': states.existence === false}" class="invalid-feedback">
                        {{ errors.existence }}
                    </div>
                </div>
            </div>
            <template v-if="! model.existence">
                <div class="row" v-for="(rule, index) in model.status_initiator_rules" :key="index">
                    <div class="d-flex align-items-end">
                        <status-initiator-rule-form :status-initiator-rule="rule" :fix="true" @updated="rule => updateRule(index, rule)"></status-initiator-rule-form>
                        <span class="pb-1 ms-3">
                            <b-button class="mb-4" variant="danger" @click="removeRule(index)"><i class="fas fa-trash-alt"></i></b-button>
                        </span>
                    </div>
                </div>
            </template>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button v-if="!fix" class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <button v-if="!model.existence" class="btn btn-success" @click.prevent="addRule()">{{ $t('base.add').ucFirst() }}</button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<style scoped>

</style>
