<script>
import {defineComponent} from 'vue'
import draggable from "vuedraggable";

export default defineComponent({
    name: "horizontal-timeline",

    components: {draggable},

    emits: ['edit', 'sort', 'remove', 'select', 'editNotifications'],

    props: {
        steps: {
            type: Array
        },

        sortable: {
            type: Boolean,
            default: true
        },

        currentStepId: {
            type: Number
        }
    },

    computed: {
        list: {
            get: function () {
                return JSON.parse(JSON.stringify(this.steps))
            },
            set: function (value) {
                if (this.sortable) {
                    this.$emit('sort', value)
                }
            },
        },

        currentClass: function () {
            return {'text-primary d-inline-block position-absolute arrow-down-wrapper': true, 'arrow-down-active': this.mouseOver === this.selected?.id};
        },

        currentStyle: function () {
            const value = this.left + 'px';

            return {left: value};
        },
    },

    data: function () {
        return {
            selected: {},
            left: '50%',
            mouseOver: null
        }
    },

    methods: {
        edit: function (id) {
            this.$emit('edit', id)
        },

        remove: function (id) {
            this.$emit('remove', id)
        },

        editNotifications: function (id) {
            this.$emit('editNotifications', id)
        },

        select: function (step, force = false) {
            if (force || step.project_step) {
                this.selected = step;
                this.$emit('select', step?.id);
                this.setArrowPosition();
            }
        },

        setArrowPosition: function () {
            const index = this.steps.findIndex(step => step.id === this.selected?.id);

            if (index > -1) {
                this.left = this.$refs.steps?.[index]?.clientWidth / 2 + this.$refs.steps?.[index]?.offsetLeft;
            }
        },
    },

    watch: {
        'currentStepId': {
            immediate: true,
            handler: function (value) {
                if (value) {
                    this.select(this.steps.find(step => step.id === value), true);
                }
            },
        },

        'steps': {
            immediate: true,
            deep: true,
            handler: function () {
                if (this.currentStepId) {
                    this.select(this.steps.find(step => step.id === this.currentStepId), true)
                }
            },
        }
    },

    created: function () {
        window.addEventListener("resize", this.setArrowPosition);
    },

    unmounted() {
        window.removeEventListener("resize", this.showFixedActions);
    },

    mounted() {
        this.setArrowPosition()
        // this.left = false;
    }
})
</script>

<template>
    <div class="hori-timeline">
        <div
            class="swiper-container slider events"
            id="timeline-carousel"
        >
            <draggable
                v-if="sortable"
                class="swiper-wrapper d-flex"
                tag="div"
                v-model="list"
                item-key="id"
            >
                <template #item="{element, index}">
                    <div :key="index" class="swiper-slide event-list flex-grow-1 cursor-pointer position-relative">
                        <ul class="list-inline mb-0 column-actions no-wrap text-end">
                            <li class="list-inline-item dropdown">
                                <a
                                    class="text-muted dropdown-toggle font-size-18 px-2"
                                    href="#"
                                    role="button"
                                    data-bs-toggle="dropdown"
                                    aria-haspopup="true"
                                >
                                    <i class="bx bx-dots-vertical-rounded"></i>
                                </a>

                                <div class="dropdown-menu dropdown-menu-end">
                                    <a class="dropdown-item cursor-pointer" @click.prevent="remove(element.id)">
                                        <div class="d-flex align-items-center">
                                            <i class="mdi mdi-trash-can font-size-14 me-2"></i>
                                            <span>{{ $t('base.delete').ucFirst() }}</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item cursor-pointer" @click.prevent="edit(element.id)">
                                        <div class="d-flex align-items-center">
                                            <i class="mdi mdi-pencil font-size-14 me-2"></i>
                                            <span>{{ $t('base.edit').ucFirst() }}</span>
                                        </div>
                                    </a>
                                    <a class="dropdown-item cursor-pointer" @click.prevent="editNotifications(element.id)">
                                        <div class="d-flex align-items-center">
                                            <i class="mdi mdi-bell-alert font-size-14 me-2"></i>
                                            <span>{{ $t('base.notifications').ucFirst() }}</span>
                                        </div>
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div @click="edit(element.id)">
                            <div class="event-date cursor-move">
                                <div class="text-primary mb-1">{{ element.sort }}</div>
                                <h5 class="mb-4 no-wrap px-2">{{ element.translation_key ? $t(element.translation_key).ucFirst() : element.name }}</h5>
                            </div>
                            <div class="event-down-icon d-flex justify-content-center align-items-center">
                                <i
                                    class="
                                          bx bx-fast-forward-circle
                                          h3
                                          text-primary
                                          down-arrow-icon
                                          mb-0
                                        "
                                ></i>
                            </div>

                            <div class="mt-3 px-3 min-h-54">
                                <p class="text-muted mb-0">
                                    {{ element.target_translation_key ? $t(element.target_translation_key) : element.target }}
                                </p>
                            </div>
                        </div>
                    </div>
                </template>
            </draggable>
            <div v-else class="swiper-wrapper d-flex">
                <div v-for="(step, index) in steps" :key="index" ref="steps" class="swiper-slide event-list flex-grow-1 cursor-pointer position-relative"
                    :class="{'opacity-50': step.id !== selected?.id}"
                     @mouseout="mouseOver = null"
                     @mouseover="mouseOver = step.id"
                     @click="select(step)">
                    <div class="position-relative">
                        <div class="event-date">
                            <div class="text-primary mb-1">{{ step.sort }}</div>
                            <h5 class="mb-4 no-wrap px-2 font-size-lg-14 font-size-xxl-18">{{ step.translation_key ? $t(step.translation_key).ucFirst() : step.name }}</h5>
                        </div>
                        <div class="event-down-icon d-flex justify-content-center align-items-center">
                            <i
                                :class="{
                                    'bx bx-fast-forward-circle text-primary': step.id === selected?.id && !step.project_step?.expired_deadline && !step.project_step?.successful,
                                    'bx bx-stop-circle text-danger': !step.project_step?.successful && !step.project_step,
                                    'bx bx-check-circle text-success': step.sort < selected?.sort || step.project_step?.successful,
                                    'bx bx-home-circle': step.project_step && !step.project_step.successful && step.id !== selected?.id && !step.project_step?.expired_deadline,
                                    'bx bx-error-circle text-warning': !step.project_step?.successful && step.project_step?.expired_deadline
                                }"
                                class="h3 down-arrow-icon mb-0"
                            ></i>
                        </div>

                        <div class="mt-3 px-3 min-h-54 d-lg-none d-xxl-block">
                            <p class="text-muted mb-0">
                                {{ step.target_translation_key ? $t(step.target_translation_key) : step.target }}
                            </p>
                        </div>
                    </div>
                </div>
                <teleport v-if="selected?.id" :key="selected.id" to="#project-step-manager">
                    <div
                        :class="currentClass"
                         :style="currentStyle"
                    >
                        <i class="fas fa-long-arrow-alt-down font-size-26"></i>
                    </div>
                </teleport>
            </div>
        </div>
    </div>
</template>

<style scoped>
@keyframes forward {
    from {
        transform: translateX(-8px);
        opacity: 1;
    }
    to {
        transform: translateX(8px);
        opacity: 0;
    }
}

@keyframes down {
    from {
        transform: translateY(-4px);
        opacity: 1;
    }
    to {
        transform: translateY(8px);
        opacity: 0;
    }
}

.hori-timeline .events .event-list:hover .down-arrow-icon {
    animation: forward 1.5s infinite linear;
}

.min-h-54 {
    min-height: 54px;
}

.swiper-slide {
    .list-inline {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        transition: 0.3s;
    }

    &:hover .list-inline {
        display: block;
    }
}

.arrow-down-wrapper {
    background-color: white;
    padding: 0 2rem;
    z-index: 1;
    top: calc(100% + 40px);
    transform: translate(-50%, -50%);
    /*left: 50%;*/
}

.arrow-down-active {
    transition: 0.3s;
}

.arrow-down-active i {
    animation: down 1s infinite linear;
}
</style>
