<script>
import {defineComponent} from 'vue'
import HorizontalTimeline from "./horizontal-timeline.vue";
import StepForm from "../forms/step-form.vue";
import {mapGetters} from "vuex";
import Swal from "sweetalert2";
import DeadlineWarningManager from "./deadline-warning-manager.vue";

export default defineComponent({
    name: "steps-manager",
    components: {DeadlineWarningManager, StepForm, HorizontalTimeline},

    props: {
        process: {
            type: Object
        }
    },

    data: function () {
        return {
            openModal: false,
            editModal: false,
            deadlineWarningModal: false,
            newStep: {},
            localSteps: this.processSteps
        }
    },

    computed: {
        ...mapGetters({
            loading: 'step/loading',
            step: 'step/item'
        }),

        steps: {
            get: function() {
                return this.$store.getters['step/list']
            },
            set: function (value) {
                return this.$store.dispatch('step/sortList', value).then(() => {
                    this.$store.dispatch('step/saveSorting')
                })
            },
        },

        nextSort: function () {
            if (!this.steps || !this.steps.length) {
                return 1
            }

            return this.steps.reduce((total, step) => {
                return step.sort >= total ? step.sort + 1 : total
            }, 0)
        },

        processId: function () {
            return this.process && this.process.id
        }
    },

    methods: {
        add: function () {
            this.newStep.process_id = this.processId
            this.newStep.sort = this.nextSort
            this.openModal = true
        },

        remove: function (id = null) {
            if (!this.steps || !this.steps.length) {
                return
            }

            Swal.fire({
                icon: 'warning',
                title: this.$t('base.delete_confirm_title'),
                text: this.$t('base.delete_confirm_message'),
                showCancelButton: true,
                showConfirmButton: true,
                customClass: {
                    icon: 'text-danger border-danger',
                    confirmButton: 'btn btn-danger',
                    cancelButton: 'btn btn-secondary'
                },
                confirmButtonText: this.$t('base.delete').ucFirst(),
                cancelButtonText: this.$t('base.cancel').ucFirst(),
            }).then(result => {
                if (result.isConfirmed) {
                    const step = id ? {} : this.steps[this.steps.length - 1]

                    this.$store.dispatch('step/destroy', step.id || id)
                }
            });
        },

        edit: function (id) {
            this.$store.dispatch('step/select', id)
            this.editModal = true
        },

        editDeadlineWarnings: function (id) {
            this.$store.dispatch('step/select', id)
            this.deadlineWarningModal = true
        },

    },

    created() {
        this.$store.dispatch('step/list', {process_id: this.processId, sorting: { column: 'sort', desc: false }, with: ['statuses', 'interactions', 'statusInitiators', 'statusInitiators.statusInitiatorRules']})
    }
})
</script>

<template>
    <b-overlay :show="loading">
        <div class="d-flex">
            <div class="d-flex align-items-center justify-content-center me-3">
                <b-button variant="outline-danger" size="sm" class="d-flex rounded-circle align-items-center justify-content-center" @click="remove(null)"><i class="bx bx-minus"></i></b-button>
            </div>
            <horizontal-timeline :steps="steps" :key="steps.length" class="flex-grow-1" @edit="edit" @sort="(newValue) => steps = newValue" @remove="remove" @edit-notifications="editDeadlineWarnings"></horizontal-timeline>
            <div class="d-flex align-items-center justify-content-center ms-3">
                <b-button variant="outline-success" size="sm" class="d-flex rounded-circle align-items-center justify-content-center" @click="add"><i class="bx bx-plus"></i></b-button>
            </div>
            <b-modal size="xl" v-model="openModal" :title="$t('base.add_new_item', {item: $tc('projects::steps.step', 1)}).ucFirst()" body-bg-variant="light" body-class="rounded" hide-footer>
                <step-form :key="openModal" :step="newStep" :redirect="false" :configured-action="true" @saved="openModal = false"></step-form>
            </b-modal>
            <b-modal size="xl" v-model="editModal" :title="$t('base.edit_item', {item: $tc('projects::steps.step', 1)}).ucFirst()" body-bg-variant="light" body-class="rounded" hide-footer>
                <step-form :key="editModal" :step="step" @saved="editModal = false"></step-form>
            </b-modal>
            <b-modal size="xl" v-model="deadlineWarningModal" :title="$t('base.edit_item', {item: $tc('projects::deadline_warnings.deadline_warning', 1)}).ucFirst()" body-bg-variant="light" body-class="rounded" hide-footer>
                <deadline-warning-manager></deadline-warning-manager>
            </b-modal>
        </div>
    </b-overlay>
</template>

<style scoped>
    .btn {
        width: 32px;
        height: 32px;
        opacity: 0.5;
        transition: 0.3s;
        &:hover {
            opacity: 1;
        }
    }
</style>
