<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <process-form :process="process">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </process-form>
                </div>
            </div>
            <div class="card" v-if="process.id">
                <div class="card-header">
                    <h4 class="card-title">{{ $tc('projects::steps.step', 2).ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <steps-manager :process="process"></steps-manager>
                </div>
            </div>
            <div class="card" v-if="process.id">
                <div class="card-header">
                    <h4 class="card-title">{{ $tc('projects::field_settings.field_setting', 2).ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <field-settings-manager :process="process"></field-settings-manager>
                </div>
            </div>
            <div class="card" v-if="process.id">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('projects::processes.relationships.statuses').ucFirst() }}</h4>
                </div>
                <div class="card-body">
                    <multiple-select id="statuses" v-model="process.statuses" store="status"></multiple-select>
                    <b-button variant="primary" @click="save">{{ $t('base.save').ucFirst() }}</b-button>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>

import {mapGetters} from "vuex";
import CallbackButton from "../../../components/elements/callback-button.vue";
import ProcessForm from "../../../components/forms/process-form.vue";
import StepsManager from "../../../components/elements/steps-manager.vue";
import FieldSettingsManager from "../../../components/elements/field-settings-manager.vue";
import MultipleSelect from "../../../components/elements/multiple-select.vue";

export default {
    components: {MultipleSelect, FieldSettingsManager, StepsManager, ProcessForm, CallbackButton},

    computed: {
        ...mapGetters({
            process: 'process/item'
        }),

        title: function () {
            return this.process.id ? this.$tc('projects::processes.process', 2).ucFirst() + ' / ' + (this.process.translation_key ? this.$t(this.process.translation_key).ucFirst() : this.process.name) : this.$t('base.create_new_item', {item: this.$tc('projects::processes.process', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/master-data/processes')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },

        save: function () {

            const data = Object.assign({id: this.process.id}, {statuses: this.process.statuses})

            return this.$store.dispatch(`process/update`, data)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (this.$route.params.id) {
            this.$store.dispatch('process/show', {id: this.$route.params.id, query: { with: ['statuses'] }})
        }
    },

    unmounted() {
        this.$store.dispatch('process/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
