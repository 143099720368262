<script>
import {mapGetters} from "vuex";
import DeadlineWarningForm from "../forms/tasks/deadline-warning-form.vue";
import SimpleTable from "./simple-table.vue";
import TransitionToggle from "../transitions/toggle.vue";

export default {
    name: "deadline-warning-manager",

    components: {TransitionToggle, SimpleTable, DeadlineWarningForm},

    computed: {
        ...mapGetters({
            step: 'step/item',
            interaction: 'interaction/item',
            loading: 'deadlineWarning/loading',
            deadlineWarning: 'deadlineWarning/item',
            deadlineWarnings: 'deadlineWarning/list'
        }),

        fields: function () {
            return [
                {
                    key: "date",
                    label: this.$t('projects::deadline_warnings.columns.date').ucFirst(),
                    formatter: (value) => {
                        if (!value) {
                            return
                        }

                        const parts = value?.replace('+', '')?.split(' ');

                        return parts?.[0] + ' ' + this.$t('dates.' + parts?.[1])
                    },
                    thClass: ''
                },
                {
                    key: "number",
                    label: this.$t('projects::deadline_warnings.columns.number').ucFirst(),
                    thClass: ''
                },
                {
                    key: "superior",
                    label: this.$t('projects::deadline_warnings.columns.superior').ucFirst(),
                    thClass: ''
                },
                {
                    key: "superiors",
                    label: this.$t('projects::deadline_warnings.columns.superiors').ucFirst(),
                    thClass: ''
                },
                {
                    key: "grace_period",
                    label: this.$t('projects::deadline_warnings.columns.grace_period').ucFirst(),
                    formatter: (value) => {
                        if (!value) {
                            return
                        }

                        const parts = value?.replace('+', '')?.split(' ');

                        return parts?.[0] + ' ' + this.$t('dates.' + parts?.[1])
                    },
                    thClass: ''
                },
                {
                    key: "operation",
                    label: this.$t('projects::deadline_warnings.columns.operation').ucFirst(),
                    thClass: ''
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ]
        },
    },

    data: function () {
        return {
            create: false
        }
    },

    methods: {
        select: function (interaction) {
            this.$store.dispatch('interaction/setItem', interaction)
        },

        fetchDeadlineWarnings: function () {
            this.$store.dispatch('deadlineWarning/list', { interaction_step_id: this.interaction?.pivot?.id, sorting: { column: 'number', desc: false } })
        },

        createNew: function () {
            this.$store.dispatch('deadlineWarning/clearItem')
            this.create = true
        },
    },

    watch: {
        interaction: {
            immediate: true,
            handler: function () {
                const id = this.interaction?.pivot?.id

                if (id) {
                    this.fetchDeadlineWarnings()
                } else {
                    this.$store.dispatch('deadlineWarning/clearList')
                }
            }
        }
    }
}
</script>

<template>
    <div v-if="step" class="deadline-warning-manager">
        <div class="d-flex justify-content-between">
            <div class="interaction-selectors">
                <b-button class="me-2" :variant="interaction?.id === item.id ? 'success' : 'primary'" v-for="(item, index) in step.interactions" :key="index" @click="select(item)">
                    <i :class="item.icon"></i>
                </b-button>
            </div>
            <div class="create-new">
                <b-button variant="primary" @click="createNew">{{ $t('base.create').ucFirst() }}</b-button>
            </div>
        </div>
        <transition-toggle>
            <deadline-warning-form class="mt-4" v-if="create === true" :deadlineWarning="deadlineWarning" :interaction-step-id="interaction?.pivot?.id" @close="create = false"></deadline-warning-form>
        </transition-toggle>
        <b-overlay v-if="create === false && interaction?.id" v-model="loading">
            <simple-table class="mt-4" :items="deadlineWarnings" :fields="fields">
                <template #superior="{value}">
                    <i class="fas" :class="{'fa-thumbs-up text-success': value === true, 'fa-thumbs-down text-danger': value !== value}"></i>
                </template>
                <template #superiors="{value}">
                    <i class="fas" :class="{'fa-thumbs-up text-success': value === true, 'fa-thumbs-down text-danger': value !== value}"></i>
                </template>
                <template #actions="{item, edit}">
                    <b-button size="sm" variant="white" @click="edit(item)">
                        <i class="fas fa-edit text-warning"></i>
                    </b-button>
                </template>
                <template #default="{close, item}">
                    <deadline-warning-form :deadlineWarning="item" :interaction-step-id="interaction?.pivot?.id" @close="close"></deadline-warning-form>
                </template>
            </simple-table>
        </b-overlay>
    </div>
</template>

<style scoped>

</style>
