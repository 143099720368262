<script>
import {defineComponent} from "vue";
import {mapGetters} from "vuex";
import TransitionToggle from "../transitions/toggle.vue";

export default defineComponent({
    name: "field-settings-manager",
    components: {TransitionToggle},

    props: {
        process: {
            type: Object,
            required: true
        }
    },

    data: function () {
        return {
            model: {}
        }
    },

    computed: {
        ...mapGetters({
            loading: 'fieldSetting/loading',
            settings: 'fieldSetting/list',
            additional: 'fieldSetting/additional',
        }),

        processId: function () {
            return this.process && this.process.id
        },

        columns: function () {
            return this.additional && this.additional.columns
        },

        dynamicColumns: function () {
            return this.additional && this.additional.dynamic_columns
        },

        fields: {
            get: function () {
                const arr = []

                if (this.columns && this.columns.length) {

                    this.columns.forEach(col => {
                        arr.push({
                            process_id: this.process.id,
                            label: this.$t('projects::projects.columns.' + col).ucFirst(),
                            value: col,
                            model: 'Appon\\Projects\\Models\\Project',
                            field_name: col,
                            visible: true
                        })
                        this.model[col] = true
                    })

                }

                if (this.dynamicColumns && this.dynamicColumns.length) {
                    this.dynamicColumns.forEach(col => {
                        arr.push({
                            process_id: this.process.id,
                            label: col.translation_key ? this.$t(col.translation_key).ucFirst() : col.name,
                            value: col.id,
                            model: 'Appon\\Projects\\Models\\Project',
                            field_id: col.id,
                            visible: true
                        })
                        this.model[col.id] = true
                    })
                }

                if (this.settings && this.settings.length) {
                    this.settings.forEach(setting => {
                        const index = arr.findIndex(item =>
                            item.model === setting.model
                            && Number(item.process_id) === Number(setting.process_id)
                            && ((!!item.field_id && Number(item.field_id) === Number(setting.field_id))
                                || (!!item.field_name && item.field_name === setting.field_name)
                            )
                        )

                        if (index !== -1) {
                            arr[index] = Object.assign(arr[index], setting)
                            this.model[arr[index].value] = arr[index].visible
                        }
                    })
                }

                return arr;
            }
        },
    },

    methods: {
        save: function (value, field) {
            let data = {
                id: field.id,
                visible: value
            }

            const method = field.id ? 'update' : 'create'

            if (!field.id) {
                data = JSON.parse(JSON.stringify(field))
                data.visible = value

                delete data.value
                delete data.label
            } else {
                this.$store.dispatch(`fieldSetting/setItem`, field)
            }

            this.$store.dispatch(`fieldSetting/${method}`, data)
        },
    },

    created() {
        this.$store.dispatch('fieldSetting/list', {process_id: this.processId})
    }
});
</script>

<template>
    <b-overlay :show="loading">
        <div class="row mb-4">
            <div class="col-auto" v-for="(field, index) in fields" :key="index">
                <div class="d-flex align-items-center border p-2 rounded mb-3" :class="{'bg-soft-secondary text-white': !model[field.value]}">
                    <transition-toggle>
                        <i v-if="!model[field.value]" class="fas fa-eye-slash me-2"></i>
                    </transition-toggle>
                    <label
                        class="form-label no-wrap mb-0 me-2"
                        :for="field.value"
                    >{{ field.label }}</label
                    >
                    <input
                        :id="field.value"
                        v-model="model[field.value]"
                        class="form-check-input"
                        :name="field.value"
                        type="checkbox"
                        @change="save(model[field.value], field)"
                    />
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<style scoped>

</style>
